@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body{
  background-color: #151515;
  color: #FFFFFF;
}

.home-list{
  /* background-color: #FFFFFF; */
  height: 2px;
  /* width: 100%; */
}

.about-bg{
  background-color: #212023;
}

.green-bg{
  background-color: #6BAFA5;
}

.gray-font{
  color: #7D7D7F;
}